import React from "react";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import paths from "@icarius-localization/paths";
import CommonPage from "@icarius-common/commonPage";
import { getLocalizedString } from "@icarius-localization/strings";
import useCalendarDefinition from "./useCalendarDefinition";
import CreateEditDialog from "./dialogs/createEditDialog";
import UploadFileDialog from "./dialogs/uploadFileDialog";
import { useHistory } from "react-router-dom";

const CalendarDefinition = () => {

  const history = useHistory();

  const {
    data,
    typeList,
    statusList,
    isLoading,
    state,
    handlers
  } = useCalendarDefinition();

  const goBackButton = () => (
    <CustomIconButton
      title={getLocalizedString("goBack")}
      onClick={() => history.push(paths.timeDefinition)}
      type={"goBack"}
    />
  )

  const createButton = () => (
    <CustomIconButton
      title={getLocalizedString("create")}
      onClick={() => handlers.handleOpenCreateDialog()}
      type={"add"}
    />
  )

  const modifyButton = (gridRef) => (
    <CustomIconButton
      title={getLocalizedString("edit")}
      onClick={() => handlers.handleOpenEditDialog(gridRef)}
      type={"edit"}
    />
  )

  const deleteButton = (gridRef) => (
    <CustomIconButton
      title={getLocalizedString("delete")}
      onClick={() => handlers.handleOpenDeleteDialog(gridRef)}
      type={"delete"}
    />
  )

  const exportButton = () =>
    <CustomIconButton
      title={"Exportar plantilla para importación"}
      onClick={handlers.handleDownloadStructure}
      type={"download"}
    />

  const importButton = () =>
    <CustomIconButton
      title={"Importar calendarios"}
      onClick={handlers.handleOpenUploadFileDialogIsOpen}
      type={"upload"}
    />

  return (
    <CommonPage
      isLoading={isLoading}
      title={'Definición de calendarios'}
      gridTitle={'Definición de calendarios'}
      columnDefPage={paths.calendarDefinition}
      rowData={data}
      menuItems={[goBackButton, createButton, modifyButton, deleteButton, exportButton, importButton]}
      hasExpand
      hasHelp
    >
      {
        (state.createDialogIsOpen || state.editDialogIsOpen) &&
        <CreateEditDialog
          open={(state.createDialogIsOpen || state.editDialogIsOpen)}
          isLoading={isLoading}
          data={state.selectedDocument}
          handleClose={handlers.handleCloseCreateEditDialog}
          typeList={typeList}
          statusList={statusList}
        />
      }
      {
        state.uploadFileDialogIsOpen &&
        <UploadFileDialog
          open={state.uploadFileDialogIsOpen}
          isLoading={isLoading}
          handleClose={handlers.handleCloseUploadFileDialogIsOpen}
        />
      }
    </CommonPage>
  )
}

export default CalendarDefinition;