import React from "react";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import paths from "@icarius-localization/paths";
import CommonPage from "@icarius-common/commonPage";
import { getLocalizedString } from "@icarius-localization/strings";
import useBenefitsForPlans from "./useBenefitsForPlans";
import CreateEditDialog from "./dialogs/createEditDialog/index";
import ImageRenderer from "@icarius-table/renderersAndFilters/imageRenderer";

const BenefitsForPlans = ({ history }) => {

  const {
    data,
    planList,
    benefitList,
    isLoading,
    state,
    handlers
  } = useBenefitsForPlans();

  const createButton = (gridRef) => (
    <CustomIconButton
      title={getLocalizedString("create")}
      onClick={() => handlers.handleOpenCreateDialog(gridRef)}
      type={"add"}
    />
  )

  const deleteButton = (gridRef) => (
    <CustomIconButton
      title={getLocalizedString("delete")}
      onClick={() => handlers.handleOpenDeleteDialog(gridRef)}
      type={"delete"}
    />
  )

  const goBackItem = () =>
    <CustomIconButton
      title={getLocalizedString("goBack")}
      onClick={() => history.push(paths.recognitionScreenSelector)}
      type={"goBack"}
    />

  return (
    <CommonPage
      isLoading={isLoading}
      title={'Beneficios para planes'}
      gridTitle={'Beneficios para planes'}
      columnDefPage={paths.benefitsForPlans}
      rowData={data}
      menuItems={[goBackItem, createButton, deleteButton]}
      hasExpand
      hasHelp
      handleRowClick={handlers.handleOpenViewDialog}
      frameworkComponents={{ ImageRenderer }}
    >
      {
        (state.createDialogIsOpen || state.viewDialogIsOpen) &&
        <CreateEditDialog
          open={(state.createDialogIsOpen || state.viewDialogIsOpen)}
          loading={isLoading}
          data={state.selectedDocument}
          handleClose={handlers.handleCloseCreateEditDuplicateDialog}
          planList={planList}
          benefitList={benefitList}
          isView={state.viewDialogIsOpen}
        />
      }
    </CommonPage>
  )
}

export default BenefitsForPlans;