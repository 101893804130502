import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLoading, getData } from "../selectors";
import { RESET_STATE } from "../actionTypes";
import {
  getAttendanceReportAction
} from "../actions";

const useAttendanceReport = () => {
  const dispatch = useDispatch();

  const data = useSelector(getData);
  const isLoading = useSelector(getLoading);

  useEffect(() => {
    dispatch(getAttendanceReportAction());
    return () => { dispatch({ type: RESET_STATE }) }
  }, [dispatch])


  return {
    isLoading,
    data,
  }
}

export default useAttendanceReport;