import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getLoading,
  getData,
  getTypeList,
  getStatusList,
} from "../selectors";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import {
  deleteCalendarDefinitionAction,
  getCalendarDefinitionAction,
  downloadStructureAction,
} from "../actions";
import { openDialogAction } from "@icarius-common/dialog/actions";

const useCalendarDefinition = () => {

  const [editDialogIsOpen, setEditDialogIsOpen] = useState(false);
  const [createDialogIsOpen, setCreateDialogIsOpen] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null); //seleccionado para editar
  const [uploadFileDialogIsOpen, setUploadFileDialogIsOpen] = useState(false);

  const dispatch = useDispatch();
  const data = useSelector(getData);
  const isLoading = useSelector(getLoading);
  const typeList = useSelector(getTypeList);
  const statusList = useSelector(getStatusList);

  useEffect(() => {
    dispatch(getCalendarDefinitionAction());
  }, [dispatch])

  const handleOpenCreateDialog = () => {
    setSelectedDocument(null);
    setCreateDialogIsOpen(true);
  }

  const handleDelete = (document) => {
    dispatch(deleteCalendarDefinitionAction({ internalCode: document.internalCode }));
  }

  const handleOpenDeleteDialog = (gridRef) => {
    let documents = gridRef.api.getSelectedRows();
    if (documents.length !== 1) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar un registro', severity: "warning" }));
      return;
    }

    if (documents[0].status === 'H') {
      dispatch(openSnackbarAction({ msg: 'No es posible eliminar el calendario porque se encuentra en estado histórico', severity: "warning" }));
      return;
    }

    dispatch(openDialogAction({
      title: "Eliminar calendario",
      msg: '¿Esta seguro que desea eliminar al calendario?',
      onConfirm: () => handleDelete(documents[0]),
    }));
  }

  const handleOpenEditDialog = (gridRef) => {
    let documents = gridRef.api.getSelectedRows();
    if (documents.length !== 1) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar un registro', severity: "warning" }));
      return;
    }

    if (documents[0].status === 'H') {
      dispatch(openSnackbarAction({ msg: 'No es posible editar el calendario porque se encuentra en estado histórico', severity: "warning" }));
      return;
    }

    setSelectedDocument(documents[0]);
    setEditDialogIsOpen(true);
  }

  const handleCloseCreateEditDialog = () => {
    setCreateDialogIsOpen(false);
    setEditDialogIsOpen(false);
    setSelectedDocument(null);
  }

  const handleDownloadStructure = () => {
    dispatch(downloadStructureAction());
  }

  const handleOpenUploadFileDialogIsOpen = () => {
    setUploadFileDialogIsOpen(true);
  }

  const handleCloseUploadFileDialogIsOpen = () => {
    setUploadFileDialogIsOpen(false);
  }

  const state = {
    editDialogIsOpen,
    createDialogIsOpen,
    selectedDocument,
    uploadFileDialogIsOpen,
  }

  const handlers = {
    handleOpenDeleteDialog,
    handleOpenCreateDialog,
    handleOpenEditDialog,
    handleCloseCreateEditDialog,
    handleDownloadStructure,
    handleOpenUploadFileDialogIsOpen,
    handleCloseUploadFileDialogIsOpen,
    handleDelete,
  }

  return {
    data,
    typeList,
    statusList,
    isLoading,
    state,
    handlers
  }
}

export default useCalendarDefinition;