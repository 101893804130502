import { useState } from "react";
import * as yup from 'yup';
import { createDateFromYYYYMM, createDateFromDDMMYYYY, formatDateYYYYMM, formatDateYYYYMMDD } from "@icarius-utils/date";

const useHandleForm = (data, createCallback, modifyCallback, invalidDataCallback, invalidTypeCallback, invalidDatesCallback) => {

  const isCreate = !Boolean(data);

  const createInitialData = () => {

    const getInitialFieldData = (fieldName) => {
      if (fieldName === 'period') {
        return isCreate ? new Date() : createDateFromYYYYMM(data[fieldName]);
      }

      if (fieldName === 'from' || fieldName === 'to') {
        return isCreate ? new Date() : createDateFromDDMMYYYY(data[fieldName]);
      }

      if (fieldName === 'status') {
        return isCreate ? "C" : data[fieldName];
      }

      return isCreate ? "" : data[fieldName];
    }

    const fieldNames = [
      'period',
      'type',
      'from',
      'to',
      'status',
    ];

    return fieldNames.reduce((initialData, fieldName) => {
      return ({
        ...initialData,
        [fieldName]: getInitialFieldData(fieldName)
      })
    }, {});
  }

  const [formData, setFormData] = useState(createInitialData());

  const dataIsValid = async () => {
    const schema = yup.object().shape({
      'period': yup.string().required(),
      'type': yup.string().required(),
      'from': yup.string().required(),
      'to': yup.string().required(),
      'status': yup.string().required(),
    });

    return await schema.isValid(formData).then((valid) => valid);
  }

  const datesAreValid = () => {
    return formData.from <= formData.to;
  }

  const submit = async () => {
    if (await dataIsValid()) {
      if (datesAreValid()) {
        let dataToSend = {
          ...formData,
          period: formatDateYYYYMM(formData.period),
          from: formatDateYYYYMMDD(formData.from),
          to: formatDateYYYYMMDD(formData.to),
        };

        isCreate ? createCallback(dataToSend) : modifyCallback({ ...dataToSend, internalCode: data.internalCode });
        return true;
      } else {
        invalidDatesCallback();
      }
    } else {
      invalidDataCallback();
      return false;
    }
  }

  const setFormValue = (value, fieldName) => {
    if (fieldName === 'status' && value === 'H') {
      invalidTypeCallback();
      return;
    }

    setFormData({
      ...formData,
      [fieldName]: value,
    })
  }

  return { isCreate, formData, setFormValue, submit };
}

export default useHandleForm;
