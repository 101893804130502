import paths from "@icarius-localization/paths";

export default [
    {
        pages: [paths.benefitsForPlans],
        config: {
            headerName: 'Plan de reconocimiento',
            field: 'planString',
            filter: "agTextColumnFilter",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: [paths.benefitsForPlans],
        config: {
            headerName: 'Estado del plan',
            field: 'planStatus',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.benefitsForPlans],
        config: {
            headerName: 'Tipo de beneficio',
            field: 'benefitString',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.benefitsForPlans],
        config: {
            headerName: 'Beneficio activo',
            field: 'benefitActive',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.benefitsForPlans],
        config: {
            headerName: 'Imagen del beneficio',
            field: 'benefitImage',
            filter: "agSetColumnFilter",
            cellRenderer: "ImageRenderer",
        }
    },
]