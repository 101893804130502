import React from "react";
import { getLocalizedString } from "@icarius-localization/strings";
import { CloseIcon } from "@icarius-icons";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import useHandleForm from "./useHandleForm";
import { createCalendarDefinitionAction, updateCalendarDefinitionAction } from "../../actions";
import { useDispatch } from "react-redux";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import Loader from "@icarius-common/loader";

const gridStyle = { height: 75, padding: "0px 10px" };

const CreateEditDialog = (props) => {

  const { open, typeList, statusList, isLoading, data, handleClose } = props;

  const dispatch = useDispatch();

  const create = (dataToSend) => {
    dispatch(createCalendarDefinitionAction(dataToSend))
      .then((res) => {
        if (res?.status === 200) {
          handleClose();
        }
      });
  }

  const modify = (dataToSend) => {
    dispatch(updateCalendarDefinitionAction(dataToSend))
      .then((res) => {
        if (res?.status === 200) {
          handleClose();
        }
      });
  }

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }

  const invalidTypeCallback = () => {
    dispatch(openSnackbarAction({ msg: 'No es posible cambiar un calendario a estado "Histórico" en forma manual', severity: "error", duration: 10000 }));
  }

  const invalidDatesCallback = () => {
    dispatch(openSnackbarAction({ msg: '"Fecha hasta" no puede ser menor a "Fecha desde"', severity: "error", duration: 10000 }));
  }

  const { isCreate, formData, setFormValue, submit } = useHandleForm(data, create, modify, openValidationError, invalidTypeCallback, invalidDatesCallback);

  return (
    <Dialog
      open={open}
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      maxWidth={"md"}
      fullWidth={true}
    >
      <div className={"dialog-container"}>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {isCreate ? "Crear calendario" : "Editar calendario"}
          <DialogTitleDivider />
        </DialogTitle>
        <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
        <DialogContent>
          <Loader open={isLoading} />
          <Grid container item xs={12}>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                <DatePicker
                  fullWidth
                  required
                  label={'Período'}
                  cancelLabel={getLocalizedString("cancel")}
                  okLabel={getLocalizedString("ok")}
                  invalidDateMessage=""
                  format="yyyy/MM"
                  margin="none"
                  value={formData.period}
                  onChange={(e) => setFormValue(e, 'period')}
                  views={["year", "month"]}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel required id={`label-type`}>{"Tipo de calendario"}</InputLabel>
                <Select
                  labelId={`label-type`}
                  id={`select-type`}
                  value={formData.type}
                  onChange={(e) => setFormValue(e.target.value, "type")}
                  margin={"none"}
                >
                  {
                    typeList.map(item => (
                      <MenuItem
                        className={"whiteText"}
                        key={item.key}
                        value={item.key}
                      >
                        {item.value}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                <DatePicker
                  fullWidth
                  required
                  label={'Fecha desde'}
                  cancelLabel={getLocalizedString("cancel")}
                  okLabel={getLocalizedString("ok")}
                  invalidDateMessage=""
                  format="dd/MM/yyyy"
                  margin="none"
                  value={formData.from}
                  onChange={(e) => setFormValue(e, 'from')}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                <DatePicker
                  fullWidth
                  required
                  label={'Fecha hasta'}
                  cancelLabel={getLocalizedString("cancel")}
                  okLabel={getLocalizedString("ok")}
                  minDateMessage=""
                  invalidDateMessage=""
                  format="dd/MM/yyyy"
                  margin="none"
                  value={formData.to}
                  onChange={(e) => setFormValue(e, 'to')}
                  minDate={formData.from}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel required id={`label-status`}>{"Estado"}</InputLabel>
                <Select
                  labelId={`label-status`}
                  id={`select-status`}
                  value={formData.status}
                  onChange={(e) => setFormValue(e.target.value, "status")}
                  margin={"none"}
                >
                  {
                    statusList.map(item => (
                      <MenuItem
                        className={"whiteText"}
                        key={item.key}
                        value={item.key}
                      >
                        {item.value}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <ButtonDialogAction onClick={handleClose} text={getLocalizedString("cancel")} />
          <ButtonDialogAction onClick={submit} isAccept text={getLocalizedString("accept")} />
        </DialogActions>
      </div>
    </Dialog>
  );
}

export default CreateEditDialog;
