import React from "react";
import ScreenSelector from "@icarius-common/screenSelector";
import paths from "@icarius-localization/paths";
import {
  TypesOfBenefitsIcon,
  OrganizationalBenefitsIcon,
  ReasonForPointAwardingIcon,
} from "@icarius-icons/";
import { getLocalizedString } from "@icarius-localization/strings";

const BenefitsSettingsBox = () => {

  const data = [
    {
      name: "Tipos de beneficios",
      icon: <TypesOfBenefitsIcon />,
      path: paths.typesOfBenefits,
    },
    {
      name: "Beneficios organizacionales",
      icon: <OrganizationalBenefitsIcon />,
      path: paths.organizationalBenefits,
    },
    {
      name: "Motivos para entrega de puntos",
      icon: <ReasonForPointAwardingIcon />,
      path: paths.reasonForPointAwarding,
    },
  ];

  return (
    <ScreenSelector
      title={getLocalizedString("benefitsSettingsBox")}
      data={data}
    />
  );
}

export default BenefitsSettingsBox;
