import paths from "@icarius-localization/paths";
import {
    dateClassRule,
    dateFormatter,
    dateValueGetter,
    dateFilterParams,
    dateComparator,
} from "@icarius-table/date";

export default [
    {
        pages: [paths.attendanceReport],
        config: {
            headerName: 'Código de empleado',
            field: 'Código de empleado',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.attendanceReport],
        config: {
            headerName: 'Apellidos y nombres',
            field: "Apellidos y nombres",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.attendanceReport],
        config: {
            headerName: 'Fecha de asistencia',
            field: "Fecha de asistencia",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
        }
    },
    {
        pages: [paths.attendanceReport],
        config: {
            headerName: 'Horario teórico',
            field: 'Horario teórico',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.attendanceReport],
        config: {
            headerName: 'Horario programado',
            field: 'Horario programado',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.attendanceReport],
        config: {
            headerName: 'Tipo de horario',
            field: 'Tipo de horario',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.attendanceReport],
        config: {
            headerName: 'Entrada programada',
            field: 'Entrada programada',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.attendanceReport],
        config: {
            headerName: 'Marca de asistencia',
            field: 'Marca de asistencia',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.attendanceReport],
        config: {
            headerName: 'Status',
            field: 'Status',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.attendanceReport],
        config: {
            headerName: 'Hora de consulta (Actual)',
            field: 'Hora de consulta (Actual)',
        }
    },
]