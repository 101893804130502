import * as actionTypes from "./actionTypes";
import {
  createCalendarDefinitionApi,
  getCalendarDefinitionApi,
  updateCalendarDefinitionApi,
  deleteCalendarDefinitionAPI,
  downloadCalendarDefinitionExcelApi,
  importCalendarDefinitionExcelApi,
} from "@icarius-connection/api";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import { getLocalizedErrorString, getLocalizedString } from "@icarius-localization/strings";
import { downloadBlob } from "@icarius-utils/download";
import { getFileExtension, getFileName } from "@icarius-utils/fileUpload";

const errorHandler = (e, dispatch) => {
  let errorString = "";
  switch (e.response?.data?.status) {
    case "INVALID_DATA":
    case "VALIDATION_ERROR":
      errorString = getLocalizedString("validationError");
      break;
    case "DUPLICATED_DATA":
      errorString = 'Ya existe un calendario con el mismo periodo y tipo de calendario';
      break;
    case "DUPLICATED_TYPE":
      errorString = 'Ya existe un calendario en estado abierto para el mismo tipo de calendario';
      break;
    case "IN_USE":
      const codes = e.response?.data?.codes;
      if (codes?.length > 1) {
        errorString = `El calendario de tiempos no puede modificarse porque ya está relacionado con los procesos de cálculo ${codes.join(', ')}`;
      } else {
        errorString = `El calendario de tiempos no puede modificarse porque ya está relacionado con el proceso de cálculo ${codes[0]}`;
      }
      break;
    default:
      errorString = getErrorStringFromError(e);
      break;
  }
  dispatch(openSnackbarAction({ msg: errorString, severity: "error" }));
}

export const getCalendarDefinitionAction = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET });
  try {
    let response = await getCalendarDefinitionApi();

    let data = response.data && response.data.data;
    let typeList = response.data && response.data.typeList;
    let statusList = response.data && response.data.statusList;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_FULFILLED,
      payload: { data, typeList, statusList },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const createCalendarDefinitionAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.CREATE });
  try {
    let response = await createCalendarDefinitionApi(dataToSend);
    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.CREATE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.CREATE_FULFILLED,
      payload: { data },
    });

    dispatch(openSnackbarAction({ msg: 'Calendario creado con éxito', severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.CREATE_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const updateCalendarDefinitionAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.MODIFY });
  try {
    let response = await updateCalendarDefinitionApi(dataToSend);
    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.MODIFY_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.MODIFY_FULFILLED,
      payload: { data },
    });

    dispatch(openSnackbarAction({ msg: 'Calendario actualizado con éxito', severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.MODIFY_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const deleteCalendarDefinitionAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.DELETE });
  try {
    let response = await deleteCalendarDefinitionAPI(dataToSend);
    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.DELETE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.DELETE_FULFILLED,
      payload: { data },
    });

    dispatch(openSnackbarAction({ msg: 'Calendario eliminado con éxito', severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.DELETE_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const downloadStructureAction = () => async (dispatch) => {
  dispatch({ type: actionTypes.DOWNLOAD });
  try {
    let response = await downloadCalendarDefinitionExcelApi({ empty: true });
    let title = response.headers["x-content-namefile"];

    if (response.status !== 200) {
      dispatch({ type: actionTypes.DOWNLOAD_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.DOWNLOAD_FULFILLED,
    });

    downloadBlob(response.data, getFileExtension(title), getFileName(title));

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.DOWNLOAD_REJECTED });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const importSpreadsheetAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.UPLOAD });
  try {
    let formData = new FormData();
    formData.append('file', dataToSend.file);

    let response = await importCalendarDefinitionExcelApi(formData);
    let title = response.headers["x-content-namefile"];
    let hasErrors = title && title.includes("Errores-");
    if (title) {
      title = title.replace("Errores-", "");
    }
    if (response.status !== 200) {
      dispatch({ type: actionTypes.UPLOAD_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.UPLOAD_FULFILLED,
    });

    if (title) {
      downloadBlob(response.data, getFileExtension(title), getFileName(title));
      dispatch(openSnackbarAction({
        msg: 'Importación finalizada, verifique el archivo para ver el resultado',
        severity: !hasErrors ? "success" : "warning",
        duration: null
      }));
      return response;
    }
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.UPLOAD_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};