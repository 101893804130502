import * as actionTypes from "./actionTypes";

const initialState = {
  data: [],
  planList: [],
  benefitList: [],
  isLoading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {

    case actionTypes.GET_FULFILLED:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
        planList: action.payload.planList,
        benefitList: action.payload.benefitList,
      };

    case actionTypes.DELETE_FULFILLED:
    case actionTypes.CREATE_FULFILLED:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
      };
      
    case actionTypes.GET:
    case actionTypes.CREATE:
    case actionTypes.DELETE:
      return { ...state, isLoading: true };

    case actionTypes.GET_REJECTED:
    case actionTypes.DELETE_REJECTED:
    case actionTypes.CREATE_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.RESET_STATE:
      return initialState;
    default:
      return state;
  }
}
