import React from "react";
import paths from "@icarius-localization/paths";
import CommonPage from "@icarius-common/commonPage";
import useAttendanceReport from "./useAttendanceReport";
import useTemplates from "@icarius-common/templates/components/useTemplates";
import TemplatesHeader from "@icarius-common/templates/components/templatesHeader";
import CreateTemplateDialog from "@icarius-common/templates/components/createTemplateDialog";

const AttendanceReport = () => {

  const {
    isLoading,
    data
  } = useAttendanceReport();

  const {
    user,
    templates,
    selectedTemplate,
    isLoadingTemplates,
    createTemplateDialogIsOpen,
    getRole,
    handleCreateTemplate,
    handleImportTemplates,
    handleExportTemplates,
    handleSelectTemplateAndUpdateGrid,
    handleOpenCreateTemplateDialog,
    handleOpenUpdateTemplateDialog,
    handleOpenDeleteTemplateDialog,
    handleCloseCreateTemplateDialog,
  } = useTemplates('ATTENDANCE_REPORT');

  const selectInput = (gridRef) => (
    <TemplatesHeader
      gridRef={gridRef}
      user={user}
      templates={templates}
      selectedTemplate={selectedTemplate}
      getRole={getRole}
      handleImportTemplates={handleImportTemplates}
      handleExportTemplates={handleExportTemplates}
      handleOpenCreateTemplateDialog={handleOpenCreateTemplateDialog}
      handleOpenDeleteTemplateDialog={handleOpenDeleteTemplateDialog}
      handleOpenUpdateTemplateDialog={handleOpenUpdateTemplateDialog}
      handleSelectTemplateAndUpdateGrid={handleSelectTemplateAndUpdateGrid}
    />
  )

  const child = (gridRef) =>
    <>
      {
        createTemplateDialogIsOpen &&
        <CreateTemplateDialog
          open={createTemplateDialogIsOpen}
          level={user.level}
          handleCreateTemplate={(role, name) => handleCreateTemplate(gridRef, role, name)}
          handleCloseDialog={handleCloseCreateTemplateDialog}
        />
      }
    </>

  return (
    <CommonPage
      isLoading={isLoading || isLoadingTemplates}
      title={`Consulta de presentismo`}
      gridTitle={`Consulta de presentismo`}
      columnDefPage={paths.attendanceReport}
      rowData={data}
      customHeader={selectInput}
      hasExpand
      hasHelp
      codeFieldName={'Código de empleado'}
    >
      {child}
    </CommonPage>
  )
}

export default AttendanceReport;