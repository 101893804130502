import React, { useEffect } from "react";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import DialogTransition from "@icarius-common/dialogTransition";
import {
  getGalleryImagesAction,
} from "@icarius-common/galleryDialog/actions";
import PaperDraggable from "@icarius-common/paperDraggable";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { CloseIcon } from "@icarius-icons";
import { getLocalizedString } from "@icarius-localization/strings";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { createBenefitsForPlansAction } from "../../../actions";
import useHandleForm from "./useHandleForm";

const gridStyle = { height: 75, padding: "0px 10px" };


const CreateEditDialog = (props) => {

  const { open, isView, planList, benefitList, loading, data, handleClose } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getGalleryImagesAction('news'));
  }, [dispatch])

  const create = (dataToSend) => {
    dispatch(createBenefitsForPlansAction(dataToSend))
      .then((res) => res?.status === 200 && handleClose());
  }

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }

  const {
    formData,
    setFormValue,
    submit,
  } = useHandleForm(data, create, openValidationError);

  if (loading) return null;

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={DialogTransition}
        PaperComponent={PaperDraggable}
        maxWidth={"sm"}
        fullWidth={true}
      >
        <div className={"dialog-container"}>
          <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
            {isView ? "Ver beneficio para plan" : "Crear beneficio para plan"}
            <DialogTitleDivider />
          </DialogTitle>
          <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
          <DialogContent>
            <Grid container item xs={12}>
              <Grid container item alignItems="center" xs={12} style={gridStyle}> {/* plan */}
                <FormControl style={{ width: "100%" }}>
                  <InputLabel required id={`label-plan`}>{"Plan de reconocimiento"}</InputLabel>
                  <Select
                    disabled={isView}
                    value={formData.plan}
                    labelId={`label-plan`}
                    id={`select-plan`}
                    onChange={(e) => setFormValue(e.target.value, "plan")}
                    margin={"none"}
                  >
                    {
                      planList.filter(item => isView ? 1 : (item.status === "A" || item.status === "D")).map(item => (
                        <MenuItem
                          className={"whiteText"}
                          key={item.key}
                          value={item.key}>
                          {item.value}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>
              <Grid container direction="row" justify="center" alignItems="flex-start">
                <Grid container item alignItems="center" xs={12} style={gridStyle}> {/* benefit */}
                  <FormControl style={{ width: "100%" }}>
                    <InputLabel required id={`label-concept`}>{"Beneficio para plan"}</InputLabel>
                    <Select
                      disabled={isView}
                      value={formData.benefit}
                      labelId={`label-benefit`}
                      id={`select-benefit`}
                      onChange={(e) => setFormValue(e.target.value, "benefit")}
                      margin={"none"}
                    >
                      {
                        benefitList.filter(item => isView ? 1 : item.active).map(item => (
                          <MenuItem
                            className={"whiteText"}
                            key={item.key}
                            value={item.key}>
                            {item.value}
                          </MenuItem>
                        ))
                      }
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          {
            !isView &&
            <DialogActions>
              <ButtonDialogAction onClick={handleClose} text={getLocalizedString("cancel")} />
              <ButtonDialogAction onClick={submit} isAccept text={getLocalizedString("accept")} />
            </DialogActions>
          }
        </div>
      </Dialog>
    </>
  );
}

export default CreateEditDialog;
