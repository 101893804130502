import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getLoading,
  getData,
  getPlanList,
  getBenefitList,
} from "../selectors";
import { RESET_STATE } from "../actionTypes";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import {
  deleteBenefitsForPlansAction,
  getBenefitsForPlansAction,
} from "../actions";
import { openDialogAction } from "@icarius-common/dialog/actions";

const useBenefitsForPlans = () => {

  const [createDialogIsOpen, setCreateDialogIsOpen] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null); //seleccionado para editar
  const [viewDialogIsOpen, setViewDialogIsOpen] = useState(false);

  const dispatch = useDispatch();
  const data = useSelector(getData);
  const isLoading = useSelector(getLoading);
  const planList = useSelector(getPlanList);
  const benefitList = useSelector(getBenefitList);

  useEffect(() => {
    dispatch(getBenefitsForPlansAction());
    return () => { dispatch({ type: RESET_STATE }) }
  }, [dispatch])

  const handleOpenDeleteDialog = (gridRef) => {
    let documents = gridRef.api.getSelectedRows();
    if (documents.length !== 1) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar un registro', severity: "warning" }));
      return;
    }

    dispatch(openDialogAction({
      title: "Eliminar tipo de acción",
      msg: "¿Desea eliminar el beneficio organizacional?",
      onConfirm: () => handleDelete(documents[0]),
    }));
  }

  const handleOpenCreateDialog = () => {
    setSelectedDocument(null);
    setCreateDialogIsOpen(true);
  }

  const handleDelete = (document) => {
    if (document.status === "H") {
      dispatch(openSnackbarAction({ msg: 'No se puede eliminar el beneficio para un plan histórico', severity: "error", duration: 10000 }));
    } else {
      dispatch(deleteBenefitsForPlansAction({ internalCode: document.internalCode }));
    }
  }

  const handleCloseCreateEditDuplicateDialog = () => {
    setCreateDialogIsOpen(false);
    setViewDialogIsOpen(false);
    setSelectedDocument(null);
  }


  const handleOpenViewDialog = (row) => {
    setSelectedDocument(row.data);
    setViewDialogIsOpen(true);
  }

  const handleCloseViewDialog = () => {
    setViewDialogIsOpen(false);
  }

  const state = {
    createDialogIsOpen,
    selectedDocument,
    viewDialogIsOpen,
  }

  const handlers = {
    handleDelete,
    handleOpenDeleteDialog,
    handleOpenCreateDialog,
    handleCloseCreateEditDuplicateDialog,
    handleCloseViewDialog,
    handleOpenViewDialog
  }

  return {
    data,
    planList,
    benefitList,
    isLoading,
    state,
    handlers
  }
}

export default useBenefitsForPlans;